@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root,
  .light {
    --border-width-thin: 0.5px;
    --border-width: 1px;
    --radius: 0.75rem;
    --brand: 359 96% 65%;
    --brand-accent-1: 359 100% 74%;
    --brand-accent-2: 348 100% 90%;
    --brand-accent-3: 7 73% 85%;
    --brand-accent-4: 0 38% 92%;
    --brand-accent-5: 32 88% 80%;
    --background: 0 0% 100%;
    --background-subtle: 0 0% 98%;
    --foreground: 0 0% 9%;
    --foreground-subtle: 222 10% 40%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 9%;
    --primary: 223 10% 14%;
    --primary-foreground: 0 0% 100%;
    --secondary: 0 0% 96%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96%;
    --muted-foreground: 0 0% 42%;
    --accent: 0 0% 96%;
    --accent-foreground: 0 0% 9%;
    --destructive: 354 70% 55%;
    --destructive-foreground: 0 0% 100%;
    --success: 162 56% 39%;
    --success-foreground: 0 0% 100%;
    --warning: 32 100% 45%;
    --warning-foreground: 34 79% 94%;
    --info: 235 48% 48%;
    --info-foreground: 228 100% 97%;
    --border: 0 0% 85%;
    --input: 0 0% 69%;
    --prototype: 240 2% 90%;
    --ring: 223 10% 14%;
    --darkest: 0 0% 9%;
    --lightest: 0 0% 100%;
    --pastel-aqua: 164 87% 85%;
    --pastel-blue: 194 100% 85%;
    --pastel-brand: 348 100% 90%;
    --pastel-green: 139 77% 81%;
    --pastel-indigo: 221 100% 82%;
    --pastel-lime: 81 85% 86%;
    --pastel-magenta: 274 100% 85%;
    --pastel-orange: 35 100% 78%;
    --pastel-pink: 305 88% 90%;
    --pastel-purple: 244 100% 85%;
    --pastel-red: 359 100% 82%;
    --pastel-yellow: 54 87% 82%;
  }

  .dark {
    --brand: 359 96% 65%;
    --brand-accent-1: 359 100% 74%;
    --brand-accent-2: 348 100% 90%;
    --brand-accent-3: 7 73% 85%;
    --brand-accent-4: 0 38% 92%;
    --brand-accent-5: 32 88% 80%;
    --background: 223 10% 14%;
    --background-subtle: 223 10% 20%;
    --foreground: 0 0% 100%;
    --foreground-subtle: 0 0% 66%;
    --card: 220 5% 11%;
    --card-foreground: 0 0% 100%;
    --popover: 220 5% 11%;
    --popover-foreground: 0 0% 100%;
    --primary: 0 0% 100%;
    --primary-foreground: 0 0% 9%;
    --secondary: 223 10% 20%;
    --secondary-foreground: 0 0% 100%;
    --muted: 223 10% 20%;
    --muted-foreground: 0 0% 58%;
    --accent: 223 10% 20%;
    --accent-foreground: 0 0% 100%;
    --destructive: 354 70% 55%;
    --destructive-foreground: 0 0% 100%;
    --success: 162 56% 39%;
    --success-foreground: 0 0% 100%;
    --warning: 32 100% 45%;
    --warning-foreground: 34 79% 94%;
    --info: 228 100% 97%;
    --info-foreground: 235 48% 48%;
    --border: 222 10% 30%;
    --input: 222 10% 40%;
    --prototype: 224 9.8% 30%;
    --ring: 0 0% 100%;
    --pastel-aqua: 164 87% 85%;
    --pastel-blue: 194 100% 85%;
    --pastel-brand: 348 100% 90%;
    --pastel-green: 139 77% 81%;
    --pastel-indigo: 221 100% 82%;
    --pastel-lime: 81 85% 86%;
    --pastel-magenta: 274 100% 85%;
    --pastel-orange: 35 100% 78%;
    --pastel-pink: 305 88% 90%;
    --pastel-purple: 244 100% 85%;
    --pastel-red: 359 100% 82%;
    --pastel-yellow: 54 87% 82%;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}

/* For scaling Sonner toasts */
.scale-97 {
  transform: scale(0.97);
}

#__next {
  @apply h-full;
}

@layer utilities {
  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .scrollbar-hidden {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
